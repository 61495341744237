import React, { useEffect } from 'react';
import { Avatar, Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { profileStore } from '../stores/ProfileStore';
import { LevelProgress } from './LevelProgress';
import { levelsStore } from '../stores/LevelsStore';
import { observer } from 'mobx-react-lite';
import { learningAssistantStore } from '../stores/learningAssistantStore';
import { useComparisonColorMap } from '../hooks/useComparisonColorMap';
import { getBarColor } from '../logic/getBarColor';
import { ComparisonIcon } from './ComparisonIcon';
import { getSkillsInCategory } from '../logic/profile';
import { DefaultProfileBreadcrumbs } from './DefaultProfileBreadcrumbs';
import { AiLearningRecommendation } from './AiLearningRecommendation';
import { AsyncDataPattern } from './AsyncDataPattern';

const { Title, Text } = Typography;

export const LearningSuggestions = observer(({ profileId, measurementId }: {
    profileId: string;
    measurementId: string
}) => {
    const colorMap = useComparisonColorMap();
    useEffect(() => {
        learningAssistantStore.reset();
        profileStore.loadProfile(profileId);
        profileStore.loadMeasurements(profileId);
        levelsStore.loadDefaultLevels();
    }, [profileId, measurementId]);

    const loadRecommendations = (categorySlug: string) => {
        learningAssistantStore.loadLearningSuggestions(
            profileId,
            measurementId,
            categorySlug);
    };

    return <AsyncDataPattern
        asyncData={[profileStore.profileLoader, profileStore.measurementsLoader, levelsStore.defaultLevels] as const}>
        {([profile, _, defaultLevels]) => {
            const measurements = profileStore.getMeasurement(measurementId);
            return (
                <>
                    <DefaultProfileBreadcrumbs profile={profile} currStep={'Learning Assistant'}/>

                    <Title level={2}>How to reach needed skills in "{profile.name}" for "{measurements.name}"</Title>
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        {profile.categories.map(category => {
                            const skillsInCategory = getSkillsInCategory(profile, category);
                            if (skillsInCategory.length === 0) {
                                return null;
                            }

                            const categoryHeader = (
                                <Space>
                                    <Text>{category.name}</Text>
                                    <Button
                                        loading={learningAssistantStore.categoriesRecommendations[category.slug]?.loading}
                                        onClick={() => loadRecommendations(category.slug)}>
                                        AI Recommendation
                                    </Button>
                                </Space>
                            );
                            return (
                                <Card title={categoryHeader}
                                      key={category.slug}>
                                    {skillsInCategory.map((skill, i) => {
                                        const levels = skill.levels ?? defaultLevels;
                                        return (
                                            <div key={skill.skillId}>
                                                <Row
                                                    style={{ padding: 10 }}
                                                    align={'middle'}>
                                                    <Col span={5}>
                                                        <Avatar
                                                            style={{ marginRight: 10 }}>{skill.name[0].toUpperCase()}</Avatar>
                                                        <Text strong>{skill.name}</Text>
                                                    </Col>

                                                    <Col span={5} sm={8} md={8} lg={8} xl={5} style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                        alignItems: 'center'
                                                    }}>
                                                        <LevelProgress
                                                            selectedLevelPosition={profile.measurements[skill.skillId]}
                                                            levels={levels}/>
                                                    </Col>

                                                    <Col span={5} sm={8} md={8} lg={8} xl={5} style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                        alignItems: 'center'
                                                    }}>
                                                        <div style={{ marginRight: 10 }}>
                                                            <ComparisonIcon
                                                                baseline={profile.measurements[skill.skillId]}
                                                                measurement={measurements.measurementData[skill.skillId]}/>
                                                        </div>
                                                        <LevelProgress
                                                            selectedLevelPosition={measurements.measurementData[skill.skillId]}
                                                            levels={levels}
                                                            barColor={getBarColor(profile.measurements[skill.skillId], measurements.measurementData[skill.skillId], colorMap)}
                                                        />
                                                    </Col>
                                                </Row>
                                                {learningAssistantStore.categoriesRecommendations[skill.categorySlug] &&
													<Row style={{ padding: 10 }}>
														<AiLearningRecommendation skill={skill}
														                          recommendationsLoader={learningAssistantStore.categoriesRecommendations[skill.categorySlug]}/>
													</Row>}
                                                {(i + 1) < skillsInCategory.length && <Divider/>}
                                            </div>
                                        );
                                    })}
                                </Card>
                            );
                        })}
                    </Space>
                </>
            );
        }}
    </AsyncDataPattern>
});
