import React, { useEffect } from 'react';
import { Avatar, Button, Card, Col, Divider, notification, Row, Space, Typography } from 'antd';
import { profileStore } from '../stores/ProfileStore';
import { LevelProgress } from './LevelProgress';
import { levelsStore } from '../stores/LevelsStore';
import { observer } from 'mobx-react-lite';
import { getSkillsInCategory } from '../logic/profile';
import { DefaultProfileBreadcrumbs } from './DefaultProfileBreadcrumbs';
import { AsyncDataPattern } from './AsyncDataPattern';
import { interviewAssistantStore } from '../stores/interviewAssistantStore';
import { reaction } from 'mobx';

const { Title, Text } = Typography;

export const InterviewSuggestionsView = observer(({ profileId }: {
    profileId: string;
}) => {
    useEffect(() => {
        profileStore.loadProfile(profileId);
        levelsStore.loadDefaultLevels();
    }, [profileId]);

    const loadQuestions = (categorySlug: string) => {
        interviewAssistantStore.loadInterviewQuestions(profileId, categorySlug);
        reaction(() => interviewAssistantStore.interviewQuestions[categorySlug]?.state.kind, (state) => {
            if (state === 'error') {
                notification.error({
                    message: 'Error',
                    description: 'Error while loading interview questions. Please try ask again, AI is not ideal...yet.'
                });
            }
        });
    };

    return <AsyncDataPattern
        asyncData={[profileStore.profileLoader, levelsStore.defaultLevels] as const}>
        {([profile, defaultLevels]) => {
            return (
                <>
                    <DefaultProfileBreadcrumbs profile={profile} currStep={'Learning Assistant'}/>
                    <Title level={2}>Interview assistant for "{profile.name}"</Title>
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        {profile.categories.map(category => {
                            const skillsInCategory = getSkillsInCategory(profile, category);
                            if (skillsInCategory.length === 0) {
                                return null;
                            }

                            const categoryHeader = (
                                <Space>
                                    <Text>{category.name}</Text>
                                    <Button
                                        loading={interviewAssistantStore.interviewQuestions[category.slug]?.loading}
                                        onClick={() => loadQuestions(category.slug)}>
                                        AI Interview Questions Recommendation
                                    </Button>
                                </Space>
                            );
                            return (
                                <Card title={categoryHeader}
                                      key={category.slug}>
                                    {skillsInCategory.map((skill, i) => {
                                        const levels = skill.levels ?? defaultLevels;
                                        return (
                                            <div key={skill.skillId}>
                                                <Row
                                                    style={{ padding: 10 }}
                                                    align={'middle'}>
                                                    <Col span={5}>
                                                        <Avatar
                                                            style={{ marginRight: 10 }}>{skill.name[0].toUpperCase()}</Avatar>
                                                        <Text strong>{skill.name}</Text>
                                                    </Col>

                                                    <Col span={5} sm={8} md={8} lg={8} xl={5} style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                        alignItems: 'center'
                                                    }}>
                                                        <LevelProgress
                                                            selectedLevelPosition={profile.measurements[skill.skillId]}
                                                            levels={levels}/>
                                                    </Col>
                                                </Row>
                                                {interviewAssistantStore.interviewQuestions[skill.categorySlug]?.state.kind === 'success'
                                                    && <Row style={{ padding: 10 }}>
                                                        {interviewAssistantStore.interviewQuestions[skill.categorySlug].data
                                                            ?.filter(q => q.skillId === skill.skillId)
                                                            .sort((a, b) => a.checkedLevel - b.checkedLevel)
                                                            .map(question => {
                                                                return <>{question.checkedLevel} - {question.question}<br/></>;
                                                            })}
													</Row>}
                                                {(i + 1) < skillsInCategory.length && <Divider/>}
                                            </div>
                                        );
                                    })}
                                </Card>
                            );
                        })}
                    </Space>
                </>
            );
        }}
    </AsyncDataPattern>
});
