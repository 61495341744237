import React from 'react';
import { Col, Layout, Row } from 'antd';
import { Link } from './react-router-wrappers/Link';
import { FormattedMessage } from 'react-intl';

const { Footer } = Layout;

export function SecondsFooter() {
    return <Footer>
        <Row>
            <Col span={4}>
                <Link to={'/app/privacy-policy'}>
                    <FormattedMessage id={'footer.privacyPolicy'}/>
                </Link>
            </Col>
            <Col span={8}>
                47seconds ©{new Date().getFullYear()} Built with ♥ by software development enthusiasts
            </Col>
            <Col span={4}>
                <FormattedMessage id={'footer.helpLink'}
                                  values={{
                                      a: chunks => <a href="mailto:support@47seconds.pro">{chunks}</a>
                                  }}/>
            </Col>
        </Row>
    </Footer>;
}
