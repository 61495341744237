import { observer } from 'mobx-react-lite';
import { List, Space, Spin, Tabs, TabsProps, Typography } from 'antd';
import { MaybeLink } from './MaybeLink';
import React from 'react';
import { Skill } from '../../model/Skill';
import { AsyncData } from '../stores/AsyncData';
import { SkillSuggestion } from '../api/aiAssistant';

const { Text } = Typography;

type AiLearningRecommendationProps = {
    skill: Skill;
    recommendationsLoader?: AsyncData<SkillSuggestion[]>;
}

export const AiLearningRecommendation = observer(({ skill, recommendationsLoader }: AiLearningRecommendationProps) => {
    if (!recommendationsLoader) {
        return null;
    }

    if (recommendationsLoader.state.kind === 'loading') {
        return <Spin/>
    }

    if (recommendationsLoader.state.kind === 'error') {
        return <Text type="danger">Error loading recommendations</Text>
    }

    const recommendationForSkill = recommendationsLoader.state.data.find(s => s.skillId === skill.skillId)
    if (!recommendationForSkill) {
        return null;
    }

    const items: TabsProps['items'] = [
        {
            key: 'learningStages',
            label: 'Learning stages',
            children: <List
                dataSource={recommendationForSkill.learningStages}
                bordered={false}
                renderItem={(item) => (
                    <List.Item>
                        {item.text}
                    </List.Item>
                )}/>
        },
        {
            key: 'learningMaterials',
            label: 'Learning materials',
            children: <List
                dataSource={recommendationForSkill.learningMaterials}
                header={<Space><Text type={'secondary'}>NOTE:
                    Links and courses might be
                    outdated</Text></Space>}
                bordered={false}
                renderItem={(item) => (
                    <List.Item>
                        <MaybeLink text={item.text}/>
                    </List.Item>
                )}/>
        },
        {
            key: 'practicalTasks',
            label: 'Practical tasks',
            children: <List
                dataSource={recommendationForSkill.practicalTasks}
                bordered={false}
                renderItem={(item) => (
                    <List.Item>
                        {item.text}
                    </List.Item>
                )}/>
        }
    ];

    return <>
        <Tabs defaultActiveKey={'learningStages'}
              items={items}/>
    </>
})
