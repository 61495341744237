import React from 'react';
import { Breadcrumb, Radio, Space, Table, theme, Typography } from 'antd';
import { MeasuredLevel, ProfileAttachedMeasurements, StoredProfile } from '../../model/StoredProfile';
import { Skill } from '../../model/Skill';
import { LevelProgress } from './LevelProgress';
import { Level } from '../../model/Level';
import { Link } from 'react-router-dom';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip } from 'recharts';
import { useComparisonColorMap } from '../hooks/useComparisonColorMap';
import { ComparisonIcon } from './ComparisonIcon';
import { getBarColor } from '../logic/getBarColor';

const { Title } = Typography;

type TableRecord = {
    key: string;
    name: string;
    baseline: MeasuredLevel;
    skill: Skill;
    [key: `comparison${string}`]: MeasuredLevel;
}

const tooltipIconsColorMap = { equal: 'white', higher: 'white', lower: 'white', notMeasured: 'white' };
function RadarTooltipContent({ payload, baseColor, defaultLevels }: { payload: undefined | { name?: string | number, value?: string | number, payload: TableRecord }[], baseColor: string, defaultLevels: Level[] }) {
    if (!(payload && payload.length > 0)) {
        return null;
    }

    const skillRow: TableRecord = payload[0].payload;

    return (
        <div style={{ backgroundColor: baseColor, opacity: 0.9, padding: 20 }}>
            <p style={{ fontWeight: 'bold' }}>{skillRow.name}</p>
            {payload.map((item) => {
                const levels = item.payload.skill.levels || defaultLevels
                const levelName = levels.find((level: Level) => level.position === item.value)?.name || item.value?.toString();
                return (
                    <p key={item.name}>
                        {item.name}: {levelName} <ComparisonIcon baseline={skillRow.baseline} measurement={item.value as number} customColorMap={tooltipIconsColorMap} />
                    </p>
                );
            })}
        </div>
    );
}

type MeasurementComparisonProps = {
    profile: StoredProfile,
    selectedMeasurements: ProfileAttachedMeasurements[],
    defaultLevels: Level[],
    view?: 'table' | 'chart',
    onViewChange: (view: 'table' | 'chart') => void
};

export function MeasurementsComparison({ defaultLevels, profile, selectedMeasurements, onViewChange, view = 'table' }: MeasurementComparisonProps) {
    const { token } = theme.useToken();
    const colorMap = useComparisonColorMap();

    const columns = [
        {
            title: 'Skill Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Baseline',
            dataIndex: 'baseline',
            key: 'baseline',
            render: (baseline: MeasuredLevel, record: TableRecord) => {
                return (
                    <LevelProgress levels={record.skill.levels ?? defaultLevels} hideName={true}
                                   selectedLevelPosition={baseline}/>
                );
            },
        },
        ...selectedMeasurements.map((selectedMeasurement, index) => ({
            title: `Measurement ${selectedMeasurement.name}`,
            dataIndex: `comparison${index}`,
            key: `comparison${index}`,
            render: (measurement: MeasuredLevel, record: TableRecord) => (
                <Space>
                    <div><ComparisonIcon baseline={record.baseline}  measurement={measurement}/></div>
                    <LevelProgress levels={record.skill.levels ?? defaultLevels}
                                   selectedLevelPosition={measurement}
                                   hideName={true}
                                   barColor={getBarColor(record.baseline, measurement, colorMap)}/>
                </Space>
            ),
        })),
    ];

    const data: TableRecord[] = profile.skills.map(skill => {
        const baselineMeasurement = profile.measurements[skill.skillId];
        const comparisonMeasurements = selectedMeasurements.map(profileAttached => profileAttached.measurementData[skill.skillId]);
        return {
            key: skill.skillId,
            name: skill.name,
            baseline: baselineMeasurement,
            skill,
            ...comparisonMeasurements
                .reduce((acc, curr, index) => ({ ...acc, [`comparison${index}`]: curr }), {}),
        };
    });

    const maxLevel = 70;
    const levelSize = 10;
    const scale = 4;
    const chartRadius = scale * maxLevel;
    const chartInnerRadiuses = Array.from({ length: defaultLevels.length }, (_, i) => (i + 1) * levelSize * scale)

    return (
        <>
            <div style={{ marginBottom: '10px' }}>
                <Breadcrumb items={[
                    { title: `${profile.name}`, path: `/app/profile/${profile.id}` },
                    { title: 'Measurements Comparison' }
                ]} itemRender={route => {
                    if (route.path === undefined) {
                        return <span>{route.title}</span>;
                    }

                    return <Link to={route.path}>{route.title}</Link>;
                }}/>
            </div>
            <Title level={1} style={{ textAlign: 'center' }}>{profile.name}</Title>
            <div style={{ display: 'flex', marginBottom: 10 }}>
                <Radio.Group size={'large'}
                             style={{ margin: '0 auto' }}
                             value={view}
                             onChange={(e) => onViewChange(e.target.value)}>
                    <Radio.Button value="table">Table</Radio.Button>
                    <Radio.Button value="chart">Radar Chart</Radio.Button>
                </Radio.Group>
            </div>
            {view === 'table' &&
				<Table dataSource={data} columns={columns} pagination={false} sticky={true} tableLayout={'auto'}
				       scroll={{ x: 500 }}/>}
            {view === 'chart' && <div style={{display: 'flex', justifyContent: 'center'}}>
				<RadarChart outerRadius={chartRadius} width={chartRadius * 3} height={chartRadius * 2.5} data={data}>
					<PolarGrid polarRadius={chartInnerRadiuses} />
					<PolarAngleAxis dataKey="name"/>
					<PolarRadiusAxis angle={25} domain={[0, maxLevel]} axisLine={false} tickCount={defaultLevels.length + 1}/>
                    <Tooltip
	                    content={({ payload }) => (<RadarTooltipContent payload={payload as any} baseColor={token.colorPrimary} defaultLevels={defaultLevels} />)}/>
					<Radar name="Baseline" dataKey="baseline" stroke={colorMap.equal} fill={colorMap.equal} fillOpacity={0.2}/>
                    {
                        selectedMeasurements.map((selectedMeasurement, index) => {
                            const color = `hsl(${(index * 360 / selectedMeasurements.length)}, 70%, 50%)`;
                            return (
                                <Radar name={selectedMeasurement.name}
                                       dataKey={`comparison${index}`}
                                       key={`comparison${index}`}
                                       dot={true}
                                       stroke={color}
                                       fill={'none'}
                                       strokeWidth={3}
                                />
                            );
                        })
                    }
					<Legend/>
				</RadarChart>
			</div>}
        </>
    );
}
