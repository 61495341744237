import { AsyncData } from './AsyncData';
import { fetchDefaultLevels } from '../api/levelsApi';
import { action } from 'mobx';

class LevelsStore {
    defaultLevels = new AsyncData(() => fetchDefaultLevels());

    @action.bound
    loadDefaultLevels() {
        if (this.defaultLevels.state.kind === 'success') {
            return;
        }

        this.defaultLevels.start();
    }
}

export const levelsStore = new LevelsStore();
