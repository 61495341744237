import { AsyncData } from '../stores/AsyncData';
import { Alert, Spin } from 'antd';
import React from 'react';
import { observer } from 'mobx-react-lite';

type InferSuccessData<TData extends readonly AsyncData<T[number]>[], T extends readonly unknown[]> =
    { readonly [P in keyof TData]: TData[P] extends AsyncData<infer R> ? R : never };
type AsyncDataProp<T extends readonly unknown[], TDatas extends readonly AsyncData<T[number]>[]> = {
    asyncData: TDatas
    children: (data: InferSuccessData<TDatas, T>) => React.ReactElement
};

function AsyncDataPatternRaw<TData extends readonly unknown[], TDatas extends readonly AsyncData<TData[number]>[]>(
    { asyncData, children }: AsyncDataProp<TData, TDatas>
) {
    if (asyncData.some(d => d.state.kind === 'loading')) {
        return <Spin tip="Loading data...">
            <Alert
                message="Please wait while all the data is loading"
                description="This may take a few moments."
                type="info"
            />
        </Spin>
    }

    if (asyncData.some(d => d.state.kind === 'error')) {
        return <Alert
            message="Error loading data"
            description="Something went wrong while loading the data. Please try to refresh the page."
            type="error"
        />
    }

    return children(asyncData.map(d => (d.state as any).data) as unknown as InferSuccessData<TDatas, TData>);
}

export const AsyncDataPattern = observer(AsyncDataPatternRaw);
