import { apiConfig } from './apiConfig';

export async function apiRequest<T>(endpoint: string, method: string = 'GET', body?: any): Promise<T> {
    const config: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    const response = await fetch(`${apiConfig.baseUrl}${endpoint}`, config);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}
