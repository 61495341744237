import { apiConfig } from './apiConfig';
import { authInfo } from './AuthHolder';

export async function echo() {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/echo`, {
        method: 'GET',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        })
    })

    // todo: errors
    return await response.json();
}
