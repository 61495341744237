import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

interface BannerProps {
    isMobile: boolean;
}

export function Banner(props: BannerProps) {
    const intl = useIntl();
    const { isMobile, ...currentProps } = props;
    return (<>
        <Helmet>
            <title>47Seconds</title>
            <meta name="keywords" content={intl.formatMessage({ id: 'homePageNew.meta.keywords' })}/>
            <meta
                name="description"
                content={intl.formatMessage({ id: 'homePageNew.meta.description' })}
            />
        </Helmet>
            <div {...currentProps} className='banner'>
                <QueueAnim
                    type={['bottom', 'top']}
                    className='banner0-text-wrapper'
                >
                    <h1 key="title" className='banner-title'>
                        {/* worth to add a nice picture here*/}
                        {/*<img src="https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png" width="100%" alt="img"/>*/}
                        47seconds.pro
                    </h1>
                    <h3 className='banner-content'>
                        Measure and Elevate Your Software Skills
                    </h3>
                    <p className='banner-content'>
                        A comprehensive platform designed for your to assess, compare, and improve.
                    </p>
                    <Link to={`/app/skills/position/new-wizard`}>
                        <Button className='banner-button'>
                            Uncover Your Skills!
                        </Button>
                    </Link>
                </QueueAnim>
                <TweenOne
                    animation={{
                        y: '-=20',
                        yoyo: true,
                        repeat: -1,
                        duration: 1000,
                    }}
                    className="banner0-icon"
                    key="icon"
                >
                    <DownOutlined
                        onClick={() => {
                            console.log('onclick');
                            document.querySelector('#first-content')!.scrollIntoView({ behavior: 'smooth' });
                        }}/>
                </TweenOne>
            </div>
        </>
    );
}
