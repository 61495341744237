import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    List,
    Modal,
    notification,
    Row,
    Space,
    Spin,
    Typography
} from 'antd';
import { levelsStore } from '../../stores/LevelsStore';
import { profileStore } from '../../stores/ProfileStore';
import { ProfileAttachedMeasurements } from '../../../model/StoredProfile';
import { comparisonStore } from '../../stores/ComparisonStore';
import { DisplayProfile } from './DisplayProfile';
import { ProfileMeasurement } from '../../components/ProfileMeasurement';
import { Helmet } from 'react-helmet';

const { Title, Text } = Typography;

export const ViewProfile = observer(() => {
    const navigate = useNavigate();
    const { profileId } = useParams();
    const [changeCount, changeTracker] = useState(0);
    const [cloneProfileModalVisible, setCloneProfileModalVisible] = useState(false);
    const [newProfileName, setNewProfileName] = useState('');

    if (!profileId) {
        return <div>Please specify profile id</div>;
    }

    useEffect(() => {
        comparisonStore.clearSelectedItems();
        profileStore.loadProfile(profileId);
        profileStore.loadMeasurements(profileId);
        levelsStore.loadDefaultLevels();
    }, [profileId]);

    const inProgressProfile = profileStore.inProgressProfile;

    if (levelsStore.defaultLevels.state.kind === 'loading' || inProgressProfile.kind === 'loading') {
        return <Spin tip="Loading profile...">
            <Alert
                message="Please wait while profile is loading"
                description="This may take a few moments."
                type="info"
            />
        </Spin>
    }

    if (inProgressProfile.kind === 'error' || levelsStore.defaultLevels.state.kind === 'error') {
        return <Alert
            message="Error loading profile"
            description="Something went wrong while loading the profile. Please try to refresh the page."
            type="error"
        />
    }

    const defaultLevels = levelsStore.defaultLevels.state.data;
    const isMeasuring = profileStore.activeComparisonMeasurement !== null;

    const handleCloneProfile = async () => {
        setCloneProfileModalVisible(false);
        if (newProfileName) {
            try {
                const clonedProfile = await profileStore.cloneProfile(profile.id, newProfileName);
                notification.success({ message: 'Profile cloned successfully' });
                navigate(`/app/profile/${clonedProfile.id}`);
            } catch (error) {
                notification.error({ message: 'Error cloning profile' });
            }
        }
    };

    const openCloneProfileModal = () => {
        setNewProfileName(profile.name);
        setCloneProfileModalVisible(true);
    };

    const handleMeasureButtonClick = () => {
        profileStore.startComparison();
    };

    const handleMeasurementChange = (skillId: string, skillLevel: number) => {
        profileStore.measureSkill(skillId, skillLevel);
        // this is a hack
        // because we send all measurement data to a component
        // which is not observable and cannot subscribe on every skill measurement
        // so we force re-render by changing state
        changeTracker(changeCount + 1);
    };

    const onMeasurementDelete = (measurement: ProfileAttachedMeasurements) => {
        Modal.confirm({
            title: `Do you want to delete measurement "${measurement.name}"?`,
            content: 'This action cannot be undone.',
            async onOk() {
                try {
                    await profileStore.deleteMeasurement(measurement.id);
                } catch (error) {
                    notification.error({
                        message: 'Error deleting measurement',
                    });
                }
            }
        });
    }

    const measurementSelection = (isSelected: boolean, measurement: ProfileAttachedMeasurements) => {
        if (isSelected) {
            comparisonStore.addSelectedItem(measurement);
        } else {
            comparisonStore.removeSelectedItem(measurement);
        }

    }

    const profile = inProgressProfile.data;
    const measurements = profileStore.activeComparisonMeasurement ?? profile.measurements;
    return (
        <div>
            <Helmet>
                <title>{profile.name} | 47seconds</title>
                <meta name="keywords"
                      content={`position-profile, software development, ${profile.name}, ${profile.categories.map(c => c.name).join(', ')}`}/>
                <meta
                    name="description"
                    content={`Software development skills needed for ${profile.name}`}
                />
            </Helmet>

            <Title level={1} style={{ textAlign: 'center' }}>{profile.name}</Title>
            <Row style={{ marginBottom: '10px' }}>
                <Col style={{ marginRight: '10px' }}>
                    <Button type="primary" size={'large'} onClick={openCloneProfileModal}>
                        Clone Profile
                    </Button>
                </Col>
                <Col style={{ marginRight: '10px' }}>
                    <Link to={`/app/profile/${profile.id}/interview-assist`}>
                        <Button type="primary" size={'large'}>
                            Interview Assistant
                        </Button>
                    </Link>
                </Col>
                {!profile.readonly && !isMeasuring &&
					<Col style={{ marginRight: '10px' }}>
						<Button type={'primary'} size={'large'} onClick={handleMeasureButtonClick}
						        style={{ marginBottom: '1rem' }}>
							Add custom measurement
						</Button>
					</Col>
                }
                {!profile.readonly && <Col>
					<Button type="primary" size={'large'} disabled={comparisonStore.selectedItems.length === 0}
					        onClick={() => {
                                navigate(`/app/profile/${profile.id}/compare?${comparisonStore.getSelectedItemsQuery()}`)
                            }}>
						Compare
					</Button>
				</Col>}
            </Row>

            <Modal
                title="Clone Profile"
                open={cloneProfileModalVisible}
                onOk={handleCloneProfile}
                onCancel={() => setCloneProfileModalVisible(false)}
            >
                <Input
                    value={newProfileName}
                    autoFocus
                    required
                    minLength={5}
                    onChange={(e) => setNewProfileName(e.target.value)}
                    placeholder="New Profile Name"
                />
            </Modal>

            <List loading={profileStore.measurementsLoader.loading}>
                {profileStore.attachedMeasurements.map(measurement => (
                    <List.Item key={measurement.id}>
                        <Checkbox className={'sec-checkbox-lg'} style={{ marginRight: '10px' }}
                                  onChange={(e) => measurementSelection(e.target.checked, measurement)}>
                            <Text strong>{measurement.name}</Text> &nbsp;
                            <Text type={'secondary'}>
                                {new Date(measurement.timestamp).toLocaleDateString()} - {Object.keys(measurement.measurementData).length} measured
                                skill(s)
                            </Text>
                        </Checkbox>
                        <Space>
                            <Link to={`/app/profile/${profile.id}/${measurement.id}/learning-assist`}>
                                <Button type={'primary'}>Learning Recommendation</Button>
                            </Link>
                            <Button type={'primary'}
                                    onClick={() => profileStore.setActiveMeasurement(measurement)}>Update</Button>
                            <Button type={'primary'} onClick={() => onMeasurementDelete(measurement)}>Delete</Button>
                        </Space>
                    </List.Item>
                ))}
            </List>
            {
                isMeasuring &&
				<Form onFinish={profileStore.saveComparison}>
					<Row style={{ marginBottom: 10 }}>
						<Col span={8}>
							<Input size={'large'}
							       autoFocus
							       placeholder={'Name your current measurement'}
							       value={profileStore.activeComparisonName}
							       onChange={e => profileStore.setMeasurementName(e.target.value)}/>
						</Col>
						<Col>
							<Button type={'primary'} size={'large'}
							        disabled={!profileStore.activeComparisonName}
							        loading={profileStore.measurementSaver?.loading}
							        htmlType={'submit'}
							>
								Save
							</Button>
							<Button size={'large'} onClick={profileStore.cancelComparison}>Cancel</Button>
						</Col>
					</Row>
				</Form>
            }
            {isMeasuring
                ? <ProfileMeasurement profile={profile}
                                      defaultLevels={defaultLevels}
                                      measurements={measurements}
                                      onMeasurementChange={handleMeasurementChange}/>
                : <DisplayProfile profile={profile} measurements={measurements} defaultLevels={defaultLevels}/>
            }
        </div>
    );
});
