import React, { useEffect } from 'react';
import { Collapse, Checkbox, Button, Spin, Alert, notification, Tag, Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import { wizardStore } from '../../stores/WizardStore';
import { Skill } from '../../../model/Skill';
import { Link } from 'react-router-dom';
import { Category } from '../../../model/Category';
import { WizardSteps } from './WizardSteps';

const { Panel } = Collapse;

export const CategoryPanelTitle: React.FC<{ category: Category, allSelectedSkills: Skill[] }> = ({ category, allSelectedSkills }) => {
    const selectedSkills = allSelectedSkills.filter(s => s.categorySlug === category.slug);
    return (
        <>
            {category.name} - <Tag color={selectedSkills.length === 0 ? 'default' : 'success'}>{selectedSkills.length} selected</Tag>
        </>
    )
}

export const SkillsSelectionStep = observer(() => {
    const { loadingSkill, skillsByCategory, selectedSkills, selectedCategories, loadSkills, toggleSkill } = wizardStore;

    useEffect(() => {
        // Load the skills for the first category initially
        if (selectedCategories.length > 0) {
            loadSkills(selectedCategories[0]);
        }
    }, [selectedCategories, loadSkills]);

    const handlePanelChange = (activeKey: string | string[]) => {
        const newActiveCategory = Array.isArray(activeKey) ? activeKey[activeKey.length - 1] : activeKey;
        if (!newActiveCategory) {
            // category is collapsed
            return;
        }

        let category = selectedCategories.find(c => c.slug === newActiveCategory);
        if (category) {
            loadSkills(category);
            return;
        }

        notification.error({
            message: 'Unexpected error',
            description: `Cannot find category ${newActiveCategory}. Try to refresh page.`
        })
    };

    const handleSkillSelection = (selected: boolean, skill: Skill) => {
        toggleSkill(selected, skill)
    }

    return (
        <div>
            <WizardSteps current={1} />
            <Collapse size={'large'} accordion onChange={handlePanelChange}>
                {selectedCategories.map((category) => (
                    <Panel header={<CategoryPanelTitle category={category} allSelectedSkills={selectedSkills} />} key={category.slug}>
                        {loadingSkill.loading && (
                            <Spin tip="Loading skills...">
                                <Alert
                                    message="Please wait while we load the skills for this category"
                                    description="This may take a few moments."
                                    type="info"
                                />
                            </Spin>
                        )}
                        {loadingSkill.error && (
                            <Alert message={loadingSkill.error} type="error"/>
                        )}
                        {skillsByCategory[category.slug] && (
                            <Checkbox.Group className="skills-step-checkbox-group">
                                {skillsByCategory[category.slug].map((skill, i) => (
                                    <>
                                        <Checkbox key={skill.skillId}
                                                  className={'sec-checkbox-lg'}
                                                  style={{ paddingBottom: '10px', fontSize: '1.3em' }}
                                                  value={skill.skillId}
                                                  checked={selectedSkills.includes(skill)}
                                                  onChange={e => handleSkillSelection(e.target.checked, skill)}
                                        >
                                            {skill.name} - {skill.description}
                                        </Checkbox>
                                        {(i < (skillsByCategory[category.slug].length - 1)) && <Divider />}
                                    </>
                                ))}
                            </Checkbox.Group>
                        )}
                    </Panel>
                ))}
            </Collapse>
            <Link to={'/app/skills/position/new-wizard/profile'}>
                <Button size='large' type="primary" style={{ marginTop: '16px' }}>
                    Next
                </Button>
            </Link>
            <Link to={'/app/skills/position/new-wizard'}>
                <Button size='large' style={{ marginLeft: '8px', marginTop: '16px' }}>
                    Previous
                </Button>
            </Link>
        </div>
    );
});
