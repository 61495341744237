import { apiRequest } from './apiClient';
import { SkillId } from '../../model/StoredProfile';

type Recommendation = { text: string };
export type SkillSuggestion = {
    skillId: SkillId,
    learningStages: Recommendation[],
    learningMaterials: Recommendation[],
    practicalTasks: Recommendation[]
};

type LearningSuggestionResponse = {
    suggestions: SkillSuggestion[];
}

export interface InterviewQuestion {
    question: string,
    skillId: string,
    checkedLevel: number
};

export type InterviewQuestionResponse = {
    questions: InterviewQuestion[];
}

export async function loadLearningSuggestions(profileId: string, measurementId: string, categorySlug: string) {
    return apiRequest<LearningSuggestionResponse>(`/api/v1/aiAssistant/${profileId}/${measurementId}/learning-suggestions?category=${categorySlug}`)
}

export async function loadInterviewSuggestions(profileId: string, categorySlug: string) {
    return apiRequest<InterviewQuestionResponse>(`/api/v1/aiAssistant/${profileId}/interview-suggestions?category=${categorySlug}`)
}
