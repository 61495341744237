import { action, makeObservable, observable } from 'mobx';
import { AsyncData } from './AsyncData';
import { InterviewQuestion, loadInterviewSuggestions } from '../api/aiAssistant';

type CategorySlug = string;

export class InterviewAssistantStore {
    @observable
    public interviewQuestions: { [key: CategorySlug]: AsyncData<InterviewQuestion[]> } = {};

    constructor() {
        makeObservable(this);
    }

    @action.bound
    reset() {
        this.interviewQuestions = {};
    }

    @action.bound
    public loadInterviewQuestions(profileId: string, categorySlug: CategorySlug) {
        const loadQuestions = new AsyncData(async () => {
            return loadInterviewSuggestions(profileId, categorySlug)
                .then(response => response.questions)
        });

        this.interviewQuestions[categorySlug] = loadQuestions;
        loadQuestions.start();
    }
}

export const interviewAssistantStore = new InterviewAssistantStore();
