import { MeasuredLevel, SkillId, StoredProfile } from '../../model/StoredProfile';
import { Level } from '../../model/Level';
import { Collapse, Radio, Row, theme } from 'antd';
import { LevelRepresentation } from './LevelRepresentation';
import { LevelProgress } from './LevelProgress';
import React from 'react';

const { Panel } = Collapse;

type ProfileMeasurementProps = {
    profile: StoredProfile;
    defaultLevels: Level[];
    measurements: { [key: SkillId]: MeasuredLevel };
    onMeasurementChange: (skillId: string, skillLevel: number) => void;
}

export const ProfileMeasurement = ({ profile, defaultLevels, measurements, onMeasurementChange }: ProfileMeasurementProps) => {
    const { token } = theme.useToken();
    return (
        <Collapse defaultActiveKey={profile.skills[0]?.skillId.toString()} accordion>
            {profile.skills.map(skill => {
                const levels = skill.levels ?? defaultLevels;
                const header = <div
                    style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <LevelRepresentation levels={levels}
                                         selectedLevel={profile.measurements[skill.skillId]}
                                         skill={skill}/>
                    <div style={{ width: 50 }}></div>
                    <LevelProgress levels={levels}
                                   barColor={token.colorSuccessActive}
                                   selectedLevelPosition={measurements[skill.skillId]}/>
                </div>;
                return (
                    <Panel header={header} key={skill.skillId.toString()}>
                        <p>{skill.description}</p>

                        <Radio.Group
                            onChange={(e) => onMeasurementChange(skill.skillId, e.target.value)}
                            value={measurements[skill.skillId]}
                        >
                            {levels.map(level => (
                                <Row key={level.position}>
                                    <Radio value={level.position} key={level.position}
                                           style={{ marginBottom: '20px' }}>
                                        <strong>{level.name}</strong> - {level.description}
                                    </Radio>
                                </Row>
                            ))}
                        </Radio.Group>
                    </Panel>
                );
            })}
        </Collapse>
    );
}
