import { apiRequest } from './apiClient';
import { Measurements, NewProfile, ProfileAttachedMeasurements, StoredProfile } from '../../model/StoredProfile';

export async function saveProfile(profile: NewProfile) {
    const url = `/api/v1/profiles`;
    return apiRequest<{ profile: StoredProfile }>(url, 'POST', profile);
}

export async function getProfile(profileId: string) {
    const url = `/api/v1/profiles/${profileId}`;
    return apiRequest<{ profile: StoredProfile }>(url, 'GET');
}

export async function cloneProfile(profileId: string, newProfileName: string): Promise<{ profile: StoredProfile }> {
    const url = `/api/v1/profiles/${profileId}/clone`;
    return apiRequest<{ profile: StoredProfile }>(url, 'POST', { name: newProfileName });
}

export async function getMeasurements(profileId: string) {
    const url = `/api/v1/profiles/${profileId}/measurements`;
    return apiRequest<{ measurements: ProfileAttachedMeasurements[] }>(url, 'GET');
}

export async function saveMeasurement(profileId: string, name: string, measurements: Measurements) {
    const url = `/api/v1/profiles/${profileId}/measurements`;
    return apiRequest<{ measurement: ProfileAttachedMeasurements }>(url, 'POST', { name, measurementData: measurements });
}

export async function updateMeasurement(profileId: string, measurement: ProfileAttachedMeasurements) {
    const url = `/api/v1/profiles/${profileId}/measurements/${measurement.id}`;
    return apiRequest<{ measurement: ProfileAttachedMeasurements }>(url, 'PUT', measurement);
}

export async function deleteMeasurement(profileId: string, measurementId: string) {
    const url = `/api/v1/profiles/${profileId}/measurements/${measurementId}`;
    return apiRequest<{ measurementId: string }>(url, 'DELETE');
}
