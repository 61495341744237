import React from 'react';
import { useComparisonColorMap } from '../hooks/useComparisonColorMap';
import { CheckOutlined, DownCircleOutlined, MinusCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { MeasurementColorMap } from '../logic/MeasurementColorMap';

export function ComparisonIcon({ baseline, measurement, customColorMap }: { baseline: number, measurement: number, customColorMap?: MeasurementColorMap }) {
    const defaultColorMap = useComparisonColorMap();
    const colorMap = customColorMap ?? defaultColorMap;
    if (measurement > baseline) {
        return <UpCircleOutlined style={{ color: colorMap.higher }}/>;
    } else if (measurement < baseline) {
        return <DownCircleOutlined style={{ color: colorMap.lower }}/>;
    } else if (measurement === baseline) {
        return <CheckOutlined style={{ color: colorMap.equal }}/>;
    } else {
        return <MinusCircleOutlined style={{ color: colorMap.notMeasured }}/>;
    }
}
