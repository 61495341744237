import { Progress, Tooltip } from 'antd';
import React from 'react';
import { Level } from '../../model/Level';
import { themeSeeds } from '../themeSeeds';

// Calculate progress percentage based on the selected level
const calculateProgress = (levels: Level[], selectedLevelPosition?: number) => {
    if (!selectedLevelPosition) return 0;
    const maxPosition = Math.max(...(levels.map(l => l.position)));
    return (selectedLevelPosition / maxPosition) * 100;
}

export function LevelProgress(props: {
    levels: Level[],
    selectedLevelPosition: number,
    barColor?: string;
    hideName?: boolean;
}) {
    const level = props.levels.find(l => l.position === props.selectedLevelPosition);
    return <>
        <Tooltip title={level ? `${level.name} - ${level.description}` : 'Not Measured'}>
            <Progress
                percent={calculateProgress(props.levels, props.selectedLevelPosition)}
                strokeColor={props.barColor ?? themeSeeds.colorPrimary}
                steps={props.levels.length}
                showInfo={false}
                strokeWidth={15}
            />
        </Tooltip>
        {!props.hideName && <div style={{ marginLeft: 15 }}><strong>{level?.name}</strong></div>}
    </>;
}
