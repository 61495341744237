import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { profileStore } from '../../stores/ProfileStore';
import { levelsStore } from '../../stores/LevelsStore';
import { MeasurementsComparison } from '../../components/MeasurementsComparison';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const MeasurementsComparisonMode: React.FC = observer(() => {
    const query = useQuery()
    const { profileId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const selectedMeasurementIds = query.getAll('measurementId');
    const view = query.get('view') || 'table'

    useEffect(() => {
        if (profileId) {
            profileStore.loadProfile(profileId);
            profileStore.loadMeasurements(profileId);
            levelsStore.loadDefaultLevels();
        }
    }, [profileId]);

    const inProgressProfile = profileStore.inProgressProfile;

    if (levelsStore.defaultLevels.state.kind === 'loading' || inProgressProfile.kind === 'loading') {
        return (
            <Spin tip="Loading profile...">
                <Alert message="Please wait while profile is loading" description="This may take a few moments." type="info" />
            </Spin>
        );
    }

    if (inProgressProfile.kind === 'error' || levelsStore.defaultLevels.state.kind === 'error') {
        return (
            <Alert
                message="Error loading profile"
                description="Something went wrong while loading the profile. Please try to refresh the page."
                type="error"
            />
        );
    }

    const profile = inProgressProfile.data;
    const selectedMeasurements = profileStore.attachedMeasurements.filter(measurement =>
        selectedMeasurementIds.includes(measurement.id)
    );
    const defaultLevels = levelsStore.defaultLevels.state.data;

    const onViewChange = (view: 'table' | 'chart') => {
        query.set('view', view);
        navigate(`${location.pathname}?${query.toString()}`);
    };

    return (
        <MeasurementsComparison
            profile={profile}
            view={view as 'table' | 'chart'}
            onViewChange={onViewChange}
            selectedMeasurements={selectedMeasurements}
            defaultLevels={defaultLevels}
        />
    );
});
