import React, { useEffect } from 'react';
import { Button, Space } from 'antd';
import { GithubOutlined } from '@ant-design/icons';
import GoogleButton from 'react-google-button'
import { FormattedMessage, useIntl } from 'react-intl';
import { apiConfig } from './api/apiConfig';
import { authInfo } from './api/AuthHolder';
import { useNavigate } from './react-router-wrappers/useNavigate';

export function AuthLogin() {
    const navigate = useNavigate();
    const intl = useIntl();

    useEffect(() => {
        if (authInfo.accessToken !== null) {
            navigate('/app/projects');
        }
    }, [navigate])

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <Space size={'large'} direction={'vertical'}>
                    <Button type="primary" block icon={<GithubOutlined style={{paddingRight: '5px'}}/>} href={`${apiConfig.baseUrl}/auth/github`}
                            size={'large'}
                            style={{
                                background: '#333',
                                color: '#fff',
                                border: 0,
                                width: 240,
                                height: 50,
                                lineHeight: '37px'
                            }}>
                        <FormattedMessage id={'auth.loginWithGithub'}/>
                    </Button>
                    <a href={`${apiConfig.baseUrl}/auth/google`}>
                        <GoogleButton label={intl.formatMessage({ id: 'auth.loginWithGoogle' })}/>
                    </a>
                </Space>
            </div>
        </div>
    );
}
