import { theme } from 'antd';

export function useComparisonColorMap() {
    const { token } = theme.useToken();
    return {
        equal: token.colorPrimary,
        higher: token.colorSuccessActive,
        lower: token.colorErrorActive,
        notMeasured: token.colorTextSecondary
    };
}
