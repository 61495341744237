import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { StoredProfile } from '../../model/StoredProfile';

export const DefaultProfileBreadcrumbs = ({ profile, currStep }: { profile: StoredProfile, currStep: string }) => (
    <div style={{ marginBottom: '10px' }}>
        <Breadcrumb items={[
            { title: `${profile.name}`, path: `/app/profile/${profile.id}` },
            { title: currStep }
        ]} itemRender={route => {
            if (route.path === undefined) {
                return <span>{route.title}</span>;
            }

            return <Link to={route.path}>{route.title}</Link>;
        }}/>
    </div>
)
