import { action, computed, makeObservable, observable, runInAction } from 'mobx';

type Success<TData> = Readonly<{
    kind: 'success';
    data: TData;
    loading: false;
}>;

type Errored = Readonly<{
    kind: 'error';
    data: null;
    loading: false;
    error: string;
    errorDetails?: any;
}>;

type Loading = Readonly<{
    kind: 'loading';
    loading: true;
}>;

type AsyncState<TData> = Success<TData> | Errored | Loading;

const receivedData = <TData>(data: TData): Success<TData> => ({
    kind: 'success',
    data,
    loading: false,
} as const);

const receivedError = (error: unknown): Errored => ({
    loading: false,
    kind: 'error',
    data: null,
    error: (error instanceof Error && error.message) || 'An unexpected error occurred'
    // todo: error details
} as const);

const newLoading = (): Loading => ({
    kind: 'loading',
    loading: true,
} as const);

interface AsyncDataOptions {
    manuallyRetriable: boolean;
}
export class AsyncData<TData> {
    public static readonly empty = <TData>() => new AsyncData<TData>(() => { throw new Error('Cannot initiate empty AsyncData') });

    @observable.ref
    public state: AsyncState<TData> = {
        kind: 'loading',
        loading: true
    };

    constructor(
        private loadCallback: () => Promise<TData>,
        private options?: AsyncDataOptions) {
        makeObservable(this);
    }

    retry() {
        this.initiate();
    }

    start() {
        this.initiate();
    }

    get manuallyRetriable() {
        return this.options?.manuallyRetriable ?? false;
    }

    @action.bound
    async initiate() {
        if (!this.manuallyRetriable && this.state.kind === 'success') {
            throw new Error('Cannot initiate already loaded AsyncData');
        }

        this.state = newLoading();
        try {
            const data = await this.loadCallback();
            runInAction(() => {
                this.state = receivedData(data);
            });
        } catch (e) {
            runInAction(() => {
                this.state = receivedError(e);
            });
        }
    }

    @computed
    get data() {
        return this.state.kind === 'success' ? this.state.data : null;
    }

    @computed
    get loading() {
        return this.state.loading;
    }

    @computed
    get error() {
        return this.state.kind === 'error' ? this.state.error : null;
    }
}
