import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Typography, Card, Button, Radio, Alert, Spin, Collapse, Row, notification } from 'antd';
import { wizardStore } from '../../stores/WizardStore';
import { levelsStore } from '../../stores/LevelsStore';
import { Skill } from '../../../model/Skill';
import { Level } from '../../../model/Level';
import { WizardSteps } from './WizardSteps';
import { LevelProgress } from '../../components/LevelProgress';

const { Title } = Typography;
const { Panel } = Collapse;

const LevelRepresentation = ({ skill, selectedLevel, levels }: {
    skill: Skill,
    selectedLevel: number | undefined,
    levels: Level[]
}) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
        <div style={{ width: 200 }}>{skill.name}</div>
        {selectedLevel !== undefined ? <LevelProgress levels={levels} selectedLevelPosition={selectedLevel}/> : null}
    </div>
)

const MeasureSkillsStep = observer(() => {
    const {
        selectedSkills,
        setProfileName,
        profileName,
        profileSaver,
        measurements,
        measureSkill,
        saveProfile
    } = wizardStore;

    const navigate = useNavigate();

    useEffect(() => {
        levelsStore.loadDefaultLevels();
    }, []);

    if (levelsStore.defaultLevels.state.kind === 'loading') {
        return <Spin tip="Loading skills...">
            <Alert
                message="Please wait while we load the levels"
                description="This may take a few moments."
                type="info"
            />
        </Spin>
    }

    if (levelsStore.defaultLevels.state.kind === 'error') {
        return <Alert
            type="error"
            message="Error"
            description="An error occurred while loading the levels. Please try refresh the page or try again later."
        />
    }

    const onSaveProfile = async () => {
        try {
            const saved = await saveProfile();
            navigate(`/app/profile/${saved.id}`)
        } catch (e) {
            notification.error({
                message: 'Cannot save profile',
                description: profileSaver?.error
            });
        }
    };

    const defaultLevels = levelsStore.defaultLevels.state.data;
    return (
        <div>
            <WizardSteps current={3}/>
            <Title level={1} style={{ textAlign: 'center' }}
                   editable={{ maxLength: 255, onChange: setProfileName }}>{profileName}</Title>
            <Card>
                <div>
                    <Collapse defaultActiveKey={selectedSkills[0]?.skillId.toString()} accordion>
                        {selectedSkills.map(skill => {
                            const levels = skill.levels ?? defaultLevels;
                            return (
                                <Panel header={<LevelRepresentation levels={levels}
                                                                    selectedLevel={measurements[skill.skillId]}
                                                                    skill={skill}/>} key={skill.skillId.toString()}>
                                    <p>{skill.description}</p>

                                    <Radio.Group
                                        onChange={(e) => measureSkill(skill.skillId, e.target.value)}
                                        value={measurements[skill.skillId]}
                                    >
                                        {levels.map(level => (
                                            <Row key={level.position}>
                                                <Radio value={level.position}
                                                       key={level.position}
                                                       style={{ marginBottom: '20px' }}>
                                                    <strong>{level.name}</strong> - {level.description}
                                                </Radio>
                                            </Row>
                                        ))}
                                    </Radio.Group>
                                </Panel>
                            );
                        })}
                    </Collapse>
                    <Button size={'large'} onClick={onSaveProfile} loading={profileSaver?.loading}>Save Profile</Button>
                </div>
            </Card>
        </div>
    );
});

export default MeasureSkillsStep;
