import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { LandingPage } from './LandingPage/LandingPage';
import { AuthLogin } from './AuthLogin';
import { AuthSuccess } from './AuthSuccess';
import { PrivacyPolicy } from './LandingPage/PrivacyPolicy';
import App from './App';
import SkillListPage from './pages/skills/SkillListPage';
import CategorySelectionStep from './pages/PosiitionWizard/CategorySelectionStep';
import { SkillsSelectionStep } from './pages/PosiitionWizard/SkillsSelectionStep';
import { ProfileReviewStep } from './pages/PosiitionWizard/ProfileRevieiwStep';
import { NewLandingPage } from './LandingPage/BeatifulPage';
import MeasureSkillsStep from './pages/PosiitionWizard/MeasureSkillsStep';
import { ViewProfile } from './pages/profile/ViewProfile';
import { MeasurementsComparisonMode } from './pages/profile/MeasurementsComparisonMode';
import { LearningSuggestionsPage } from './pages/profile/LearningSuggestionPage';
import { InterviewAssistantPage } from './pages/profile/InterviewAssistantPage';

const children = [
    {
        path: '',
        element: <LandingPage/>,
    },
    {
        path: 'privacy-policy',
        element: <PrivacyPolicy/>,
    },
    {
        path: 'auth/login',
        element: <AuthLogin/>
    },
    {
        path: 'auth/success',
        element: <AuthSuccess/>
    },
    {
        path: 'skills/all',
        element: <SkillListPage />
    },
    {
        path: 'skills/position/new-wizard',
        element: <CategorySelectionStep />
    },
    {
        path: 'skills/position/new-wizard/skills',
        element: <SkillsSelectionStep />
    },
    {
        path: 'skills/position/new-wizard/profile',
        element: <ProfileReviewStep />
    },
    {
        path: 'skills/position/new-wizard/measure',
        element: <MeasureSkillsStep />
    },
    {
        path: 'profile/:profileId',
        element: <ViewProfile />
    },
    {
        path: 'profile/:profileId/compare',
        element: <MeasurementsComparisonMode />
    },
    {
        path: 'profile/:profileId/:measurementId/learning-assist',
        element: <LearningSuggestionsPage />
    },
    {
        path: 'profile/:profileId/interview-assist',
        element: <InterviewAssistantPage />
    }
];
export const routes = createBrowserRouter([
    {
        path: '/',
        element: <NewLandingPage/>,
    },
    {
        path: '/app',
        element: <App />,
        children: children
    },
]);
