import { notification } from 'antd';
import { AsyncData } from './AsyncData';
import { fetchCategories } from '../api/categoriesApi';
import { Category } from '../../model/Category';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { getSkillsByCategory } from '../api/skilsApi';
import { Skill } from '../../model/Skill';
import { MeasuredLevel, NewProfile, SkillId, StoredProfile } from '../../model/StoredProfile';
import { saveProfile } from '../api/profile';

let localIdCounter = 1;

class WizardStore {
    @observable.ref
    categoriesLoader: AsyncData<Category[]>;

    @observable.ref
    profileSaver?: AsyncData<{ profile: StoredProfile }>;

    @observable.ref
    loadingSkill: AsyncData<Skill[]> = new AsyncData<Skill[]>(() => getSkillsByCategory(this.selectedCategories[0].slug))

    @observable
    profileName = 'Auto Generated Profile Name #' + localIdCounter;

    @observable
    skillsByCategory: { [key: string]: Skill[] } = {};

    @observable
    selectedCategories: Readonly<Category>[] = [];

    @observable
    selectedSkills: Skill[] = [];

    @observable
    measurements: Record<SkillId, MeasuredLevel> = {};

    constructor() {
        this.categoriesLoader = new AsyncData(fetchCategories);
        makeObservable(this);
    }

    loadCategories() {
        this.categoriesLoader.initiate();
    }

    @action.bound
    async loadSkills(category: Category) {
        this.loadingSkill = new AsyncData(() =>
            this.skillsByCategory[category.slug]
                ? Promise.resolve(this.skillsByCategory[category.slug])
                : getSkillsByCategory(category.slug));

        await this.loadingSkill.initiate();
        if (this.loadingSkill.error) {
            notification.error({
                message: 'Cannot load skills for category ' + category.slug,
                description: this.loadingSkill.error
            });
            return;
        }

        runInAction(() => {
            this.skillsByCategory[category.slug] = (this.loadingSkill.data || []).sort((a, b) => a.description > b.description ? -1 : 1);
        })
    }

    @action.bound
    toggleSkill(selected: boolean, skill: Readonly<Skill>) {
        const skillById = (s: Skill) => s.skillId === skill.skillId;

        if (selected) {
            // is mobx supports set?
            if (!this.selectedSkills.some(skillById)) {
                this.selectedSkills.push(skill);
            }
        } else {
            const index = this.selectedSkills.findIndex(skillById);
            if (index !== -1) {
                this.selectedSkills.splice(index, 1);
            }
        }
    }

    @action.bound
    setProfileName(name: string) {
        this.profileName = name;
    }

    @computed
    get categories() {
        return this.categoriesLoader.data;
    }

    @computed
    get loadingCategories() {
        return this.categoriesLoader.loading;
    }

    @action.bound
    selectCategory(category: Category) {
        // Check if the category is already selected
        if (!this.isCategorySelected(category)) {
            this.selectedCategories.push(category);
        }
    }

    @action.bound
    deselectCategory(category: Category) {
        const index = this.selectedCategories.findIndex(c => c.slug === category.slug);
        if (index !== -1) {
            this.selectedCategories.splice(index, 1);
        }
    }

    @action.bound
    measureSkill(skillId: SkillId, level: MeasuredLevel) {
        this.measurements[skillId] = level;
    }

    @action.bound
    saveProfile = async () => {
        // save profile to local storage, build a profile object set unique id and return it as result
        const profile: NewProfile = {
            name: this.profileName,
            categories: this.selectedCategories,
            skills: this.selectedSkills,
            measurements: this.measurements
        };
        const profileSaving = new AsyncData(() => saveProfile(profile));
        this.profileSaver = profileSaving;
        await profileSaving.initiate();
        const state = profileSaving.state;
        if (state.kind === 'success') {
            return state.data.profile;
        }

        throw new Error('Cannot save profile');
    }

    // note that category reference might be different due to mob wrap in observable
    getSelectedCategories = () => {
        return this.selectedCategories;
    }

    isCategorySelected = (category: Category) => {
        return this.selectedCategories.some(c => c.slug === category.slug);
    }

    public getLocalProfileById(profileId: number) {
        let localstorageProfile = localStorage.getItem('profile_' + profileId);
        if (localstorageProfile == null) {
            return null;
        }
        return JSON.parse(localstorageProfile) as StoredProfile;
    }

    public updateLocalProfile(updatedProfile: StoredProfile) {
        localStorage.setItem('profile_' + updatedProfile.id, JSON.stringify(updatedProfile));
    }
}

export const wizardStore = new WizardStore();
