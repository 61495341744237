import React from 'react';
import { useParams } from 'react-router-dom';
import { InterviewSuggestionsView } from '../../components/InterviewSuggestionsView';

export const InterviewAssistantPage: React.FC = () => {
    const { profileId} = useParams<{ profileId: string }>();
    if (!profileId) return (<div>Invalid URL</div>);

    return (
        <div>
            <InterviewSuggestionsView profileId={profileId} />
        </div>
    );
};
