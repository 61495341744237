import { ComponentProps, FC } from 'react';
import { Link as OriginalLink, useParams } from 'react-router-dom';

export const Link: FC<ComponentProps<typeof OriginalLink>> = (props) => {
    const { lang } = useParams();
    const newProps = { ...props };
    if (!props.relative && props.to && lang && typeof props.to === 'string') {
        const to = props.to.startsWith('/') ? props.to : `/${props.to}`;
        newProps.to = `/${lang}${to}`
    }

    return <OriginalLink {...newProps} />
}
