import { action, autorun, makeObservable, observable, runInAction } from 'mobx';
import {AsyncData} from './AsyncData';
import {cloneProfile, deleteMeasurement, getMeasurements, getProfile, saveMeasurement, updateMeasurement} from '../api/profile';
import { MeasuredLevel, ProfileAttachedMeasurements, SkillId, StoredProfile } from '../../model/StoredProfile';
import {notification} from "antd";

export class ProfileStore {
    @observable.ref
    public profileLoader: AsyncData<StoredProfile> = AsyncData.empty();

    @observable.ref
    public measurementsLoader: AsyncData<ProfileAttachedMeasurements[]> = AsyncData.empty();

    @observable
    public activeComparisonMeasurement: Record<SkillId, MeasuredLevel> | null = null;

    @observable
    public activeComparisonName?: string;

    @observable.ref
    public measurementSaver: AsyncData<{ measurement: ProfileAttachedMeasurements }> | null = null

    @observable
    public attachedMeasurements: ProfileAttachedMeasurements[] = [];

    @observable
    private editingMeasurement?: ProfileAttachedMeasurements;

    constructor() {
        makeObservable(this);
    }

    public get inProgressProfile() {
        return this.profileLoader.state;
    }

    @action.bound
    loadProfile(profileId: string) {
        if (this.profileLoader.state.kind === 'success' && this.profileLoader.state.data.id === profileId) {
            return;
        }

        this.profileLoader = new AsyncData(() => getProfile(profileId).then(data => data.profile));
        this.profileLoader.start();
    }

    @action.bound
    loadMeasurements(profileId: string) {
        this.measurementsLoader = new AsyncData(() => getMeasurements(profileId).then(data => data.measurements));
        this.measurementsLoader.start();
        autorun(() => {
            if (this.measurementsLoader.state.kind === 'success') {
                this.attachedMeasurements = this.measurementsLoader.state.data;
            }
        });
    }

    @action.bound
    public setMeasurementName(name: string) {
        this.activeComparisonName = name;
    }

    @action.bound
    public measureSkill(skillId: SkillId, skillLevel: MeasuredLevel) {
        if (this.activeComparisonMeasurement) {
            this.activeComparisonMeasurement[skillId] = skillLevel;
            return;
        }

        if (this.profileLoader.data) {
            this.profileLoader.data.measurements[skillId] = skillLevel;
        }
    }

    public getMeasurement(measurementId: string) {
        if (this.measurementsLoader.state.kind !== 'success') {
            throw new Error('Measurements are not loaded yet');
        }

        const measurement = this.measurementsLoader.state.data
            .find(m => m.id === measurementId);
        if (!measurement) {
            throw new Error('Measurement not found');
        }

        return measurement;
    }

    cloneProfile = async (profileId: string, newProfileName: string) => {
        const { profile } = await cloneProfile(profileId, newProfileName);
        return profile;
    };

    @action.bound
    public startComparison() {
        this.activeComparisonMeasurement = {};
    }

    @action.bound
    public cancelComparison() {
        this.activeComparisonMeasurement = null;
        this.activeComparisonName = undefined;
    }

    @action.bound
    public async saveComparison() {
        if (this.profileLoader.state.kind !== 'success') {
            return;
        }

        const profileId = this.profileLoader.state.data.id;
        this.measurementSaver = this.editingMeasurement
            ? new AsyncData(() => updateMeasurement(profileId, {...this.editingMeasurement!, name: this.activeComparisonName!, measurementData: this.activeComparisonMeasurement!}))
            : new AsyncData(() => saveMeasurement(profileId, this.activeComparisonName!, this.activeComparisonMeasurement!));
        await this.measurementSaver.initiate();
        if (this.measurementSaver.state.kind === 'success') {
            runInAction(() => {
                this.activeComparisonMeasurement = null;
                this.activeComparisonName = undefined;
                this.editingMeasurement = undefined;
            });
            await this.loadMeasurements(profileId);
            return;
        }

        if (this.measurementSaver.state.kind === 'error') {
            notification.error({
                message: 'Could not save measurement',
                description: this.measurementSaver.state.error
            });
        }
    }

    @action.bound
    public async deleteMeasurement(measurementId: string) {
        await deleteMeasurement(this.profileLoader.data!.id, measurementId);
        await this.loadMeasurements(this.profileLoader.data!.id);
    }

    @action.bound
    setActiveMeasurement(measurement: ProfileAttachedMeasurements) {
        this.editingMeasurement = measurement;
        this.activeComparisonMeasurement = {...measurement.measurementData};
        this.activeComparisonName = measurement.name;
    }
}

export const profileStore = new ProfileStore();
