import { MeasurementColorMap } from './MeasurementColorMap';

export function getBarColor(baseline: number, measurement: number, colorMap: MeasurementColorMap) {
    if (measurement > baseline) {
        return colorMap.higher;
    } else if (measurement < baseline) {
        return colorMap.lower;
    } else if (measurement === baseline) {
        return colorMap.equal;
    } else {
        return colorMap.equal;
    }
}
