import React from 'react';
import { Button, Layout, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const LandingPage: React.FC = () => {
    const intl = useIntl();
    return (
        <>
            <Helmet>
                <title>47Seconds</title>
                <meta name="keywords" content={intl.formatMessage({ id: 'homePageNew.meta.keywords' })}/>
                <meta
                    name="description"
                    content={intl.formatMessage ({ id: 'homePageNew.meta.description' })}
                />
            </Helmet>
            <Content className={'page-content'}>
                <div className="site-layout-content">
                    <Title>Revolutionizing Skill Development</Title>
                    <Title level={2} style={{ paddingTop: 120 }}>
                        Welcome to 47seconds.pro. We are reshaping the way individuals and organizations approach skill
                        development in the software industry.
                    </Title>
                    <Paragraph style={{ fontSize: '2em', paddingTop: 100 }}>
                        Discover skills. Craft your learning path. Grow at your own pace. Share your journey. Achieve
                        your professional aspirations. We believe everyone has the potential to excel - it's just a
                        matter of finding the right path. And we're here to guide you along the way.
                    </Paragraph>
                    <Paragraph style={{ fontSize: '2em', paddingTop: 100 }}>
                        Excited about transforming your future of learning? Lets start from creating profile then!
                    </Paragraph>
                    <Link to={`/app/skills/position/new-wizard`}><Button type='primary' block
                                                                     style={{ fontSize: '2em', padding: '50px', lineHeight: '0em' }}
                                                                     size={'large'}>Press to try</Button></Link>
                </div>
            </Content>
        </>
    );
};
