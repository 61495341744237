import React, { PureComponent } from 'react';
import { Row, Col, Typography, Button, Space } from 'antd';
import { Link } from 'react-router-dom';

interface ContentProps {
    isMobile: boolean;
}

const { Title, Paragraph } = Typography;


type ProfileItemProps = {
    to: string;
    imgPath: string;
    imgAlt: string;
    title: string;
    description: string;
    imgPosition?: 'right' | 'left';
};

const PublicProfileItem = ({ to, imgPath, imgAlt, title, description, imgPosition = 'left' }: ProfileItemProps) => {
    const img = <div className="content-block-image">
        <img src={imgPath} alt={imgAlt}/>
    </div>;
    return (
        <Link to={to}>
            <div className='content0-block-item content-profile-item'>
                {imgPosition === 'left' && img}
                <div>
                    <Title level={3}>{title}</Title>
                    <Paragraph style={{ fontSize: '1.2em' }}>{description}</Paragraph>
                </div>
                {imgPosition === 'right' && img}
            </div>
        </Link>
    );
}

export class PublicProfiles extends PureComponent<ContentProps> {
    render() {
        const { isMobile, ...props } = this.props;
        return (
            <div id={"public-profiles"} {...props} className='home-page-wrapper content-wrapper'>
                <div className='home-page content0'>
                    <Space direction={'vertical'} size={100}>
                        <div className='title-wrapper'>
                            <h4>
                                Explore position profiles
                            </h4>
                        </div>

                        <Col className='content-block content-block__interactive' md="8" xs="24">
                            <PublicProfileItem
                                to={`/app/profile/e241da6b-4ca3-4d6d-a21e-0e5ca5bd1111`}
                                imgPath={require('../../assets/solution_architect_profile_radar.png')}
                                imgAlt="solution architect radar chart"
                                title="Solution Architect"
                                description="Forge the future of technology with robust and scalable solutions tailored to your business needs."
                            />
                        </Col>
                        <Col className='content-block content-block__interactive' md="8" xs="24">
                            <PublicProfileItem
                                to={`/app/profile/b297b2bf-93ae-48e9-8be9-ff861446baba`}
                                imgPath={require('../../assets/devops_eng_profile_radar.png')}
                                imgPosition={'right'}
                                imgAlt="DevOps Engineer skills radar chart example"
                                title="DevOps Engineer"
                                description="Streamline your development pipeline, ensuring faster deployments and higher code quality."
                            />
                        </Col>
                        <Col className='content-block content-block__interactive' md="8" xs="24">
                            <PublicProfileItem
                                to={`/app/profile/e4c7a751-b7cd-4768-9f5b-a2e415279e3f`}
                                imgPath={require('../../assets/engineering_manager_radar.png')}
                                imgAlt="Engineering Manager skills radar chart example"
                                title="Engineering Manager"
                                description="Lead your tech team to success with seasoned leadership and a forward-thinking approach to problem-solving."
                            />
                        </Col>
                        <Row justify={'center'} align={'middle'}>
                            <Link to={`/app/skills/position/new-wizard`} style={{ margin: '0 auto' }}>
                                <Button className='banner-button'>
                                    Create Your Own Profile
                                </Button>
                            </Link>
                        </Row>
                    </Space>

                </div>
            </div>
        );
    }
}
