import React from 'react';
import { Button, Typography } from 'antd';

const { Text } = Typography;
;
export const MaybeLink = ({ text }: { text: string }) => {
    if (text.startsWith('https://')) {
        return (
            <Button href={text} type="link" target="_blank" rel="noopener noreferrer">{text}</Button>
        );
    }

    return (
        <Text>
            {text}
        </Text>
    );
}
