import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { authInfo } from './api/AuthHolder';
import { useNavigate } from './react-router-wrappers/useNavigate';
import { echo } from './api/echo';

export const AuthSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [ , setUser ] = useState();

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        if (typeof token !== 'string') {
            navigate('/auth/login?error=true');
            return;
        }

        authInfo.accessToken = token;

        echo().then(user => {
            setUser(user);
            navigate('/app/projects', { replace: true })
        }).catch(() => {
            navigate('/auth/login?error=true')
        });
    }, [navigate, location.search])

    return <h2><FormattedMessage id={'auth.authVerification'} /></h2>
}
