import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { routes } from './Routes';
import { ConfigProvider, theme } from 'antd';
import { themeSeeds } from './themeSeeds';
import { IntlProvider } from 'react-intl';
import { messages } from './defaultMessages';

const rootDomElement = document.getElementById('root') as HTMLElement;
const app = <React.StrictMode>
    <ConfigProvider theme={{
        algorithm: theme.darkAlgorithm,
        token: themeSeeds
    }}>
        <IntlProvider locale={'en'} key={'en'} messages={messages}>
            <RouterProvider router={routes}/>
        </IntlProvider>
    </ConfigProvider>
</React.StrictMode>;

if (rootDomElement.hasChildNodes()) {
    ReactDOM.hydrateRoot(rootDomElement, app);
} else {
    const root = ReactDOM.createRoot(
        rootDomElement
    );
    root.render(app);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
