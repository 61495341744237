// SkillListPage.tsx
import React, { useEffect, useState } from 'react';
import { Card, List, Skeleton } from 'antd';
import { Skill } from '../../../model/Skill';
import { getSkillsByCategory } from '../../api/skilsApi';
import { fetchCategories } from '../../api/categoriesApi';
import { Category } from '../../../model/Category';

const SkillListPage = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [skills, setSkills] = useState<{[key: string]: Skill[]}>({});
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            try {
                const categories = await fetchCategories();
                setCategories(categories);

                let skillsByCategory: {[key: string]: Skill[]} = {};
                for (const category of categories) {
                    const skills = await getSkillsByCategory(category.slug);
                    skillsByCategory[category.slug] = skills;
                }

                setSkills(skillsByCategory);
            } catch (error) {
                setError(error instanceof Error ? error.message : error as string);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) {
        return <Skeleton />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h1>Skills</h1>
            {Object.keys(skills).map(categorySlug => (
                <Card title={categories.find(c => c.slug === categorySlug)?.description} style={{ marginBottom: '16px' }} key={categorySlug}>
                    <List
                        itemLayout="horizontal"
                        dataSource={skills[categorySlug]}
                        renderItem={skill => (
                            <List.Item>
                                <List.Item.Meta
                                    title={skill.name}
                                    description={skill.description}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            ))}
        </div>
    );
}

export default SkillListPage;
