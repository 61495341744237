import React, { Component } from 'react';
// import { enquireScreen } from 'enquire-js';
import { Banner } from './Banner';
import { Why47Seconds } from './Why47Seconds';
import './antMotionStyle.css';
import { SecondsFooter } from '../../SecondsFooter';
import { PublicProfiles } from './PublicProfiles';

interface State {
    isMobile: boolean;
    show: boolean;
}

export class NewLandingPage extends Component<{}, State> {
    private dom: any;

    constructor(props: {}) {
        super(props);
        const isMobile = false;
        this.state = {
            isMobile,
            show: !window.location.port,
        };
    }

    componentDidMount() {
        // Adapt to mobile screen;
        // enquireScreen((b) => {
        //     this.setState({ isMobile: !!b });
        // });
    }

    render() {
        const children = [
            <Banner key={'banner'}
                isMobile={this.state.isMobile}
            />,
            <Why47Seconds key={'why47seconds'}
                isMobile={this.state.isMobile}
            />,
            <PublicProfiles key={'publicProfiles'} isMobile={this.state.isMobile} />,
            <div key={'47SecondsFooter'} style={{marginTop: '100px'}}>
                <SecondsFooter />
            </div>
        ];

        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {children}
            </div>
        );
    }
}
