import React from 'react';
import { Skill } from '../../model/Skill';
import { Level } from '../../model/Level';
import { LevelProgress } from './LevelProgress';

export const LevelRepresentation = ({ skill, selectedLevel, levels }: {
    skill: Skill,
    selectedLevel: number | undefined,
    levels: Level[]
}) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
        <div style={{ width: 200 }}>{skill.name}</div>
        {selectedLevel !== undefined ? <LevelProgress levels={levels} selectedLevelPosition={selectedLevel}/> : null}
    </div>
)
