import React from 'react';
import { useParams } from 'react-router-dom';
import { LearningSuggestions } from '../../components/LearningSuggestions';

export const LearningSuggestionsPage: React.FC = () => {
    const { profileId, measurementId } = useParams<{ profileId: string; measurementId: string }>();
    if (!profileId || !measurementId) return (<div>Invalid URL</div>);

    return (
        <div>
            <LearningSuggestions profileId={profileId} measurementId={measurementId} />
        </div>
    );
};
