import React from 'react';
import { Measurements, StoredProfile } from '../../../model/StoredProfile';
import { Avatar, Card, Col, Row, Typography } from 'antd';
import { LevelProgress } from '../../components/LevelProgress';
import { Level } from '../../../model/Level';
const { Text } = Typography;

export const DisplayProfile = ({ profile, measurements, defaultLevels }: { profile: StoredProfile, measurements: Measurements, defaultLevels: Level[] }) => (
    <>
        {profile.categories.map(category => {
            const skillsInCategory = profile.skills
                .filter(skill => skill.categorySlug === category.slug)
                .sort((a, b) => a.name.localeCompare(b.name));
            if (skillsInCategory.length === 0) {
                return null;
            }

            return (
                <Card title={`${category.name} - ${category.description}`} style={{ marginBottom: '1em' }} key={category.slug}>
                    {skillsInCategory.map(skill => {
                        const levels = skill.levels ?? defaultLevels;
                        return (
                            <Row
                                style={{ padding: 12 }}
                                align={'middle'}
                                key={skill.skillId}>

                                <Col span={5} sm={8} md={8} lg={8} xl={5} style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center'
                                }}>
                                    <LevelProgress
                                        selectedLevelPosition={measurements[skill.skillId]}
                                        levels={levels}/>
                                </Col>
                                <Col span={19}>
                                    <Avatar style={{marginRight: 10}}>{skill.name[0].toUpperCase()}</Avatar>
                                    <Text strong>{skill.name}: </Text>
                                    <Text type="secondary">{skill.description}</Text>
                                </Col>
                            </Row>
                        );
                    })}
                </Card>
            );
        })}
    </>
)
