import React, { PureComponent } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Typography, Button } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { CarryOutTwoTone, DiffTwoTone, FundTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface ContentProps {
    isMobile: boolean;
}

const { Title, Paragraph } = Typography;

export class Why47Seconds extends PureComponent<ContentProps> {
    render() {
        const { isMobile, ...props } = this.props;
        return (
            <div id={"first-content"} {...props} className='home-page-wrapper content-wrapper'>
                <div className='home-page content0'>
                    <div className='title-wrapper' style={{ marginBottom: 100 }}>
                        <h4>
                            Why 47seconds.pro?
                        </h4>
                    </div>
                    <OverPack playScale={0.3}>
                        <QueueAnim
                            type="bottom"
                            key="block"
                            leaveReverse
                            component={Row as any}
                            style={{ marginBottom: 100 }}
                            componentProps={{ className: 'content0-block-wrapper', align: 'middle', justify: 'center' }}
                        >
                            <Col className='content-block' md="8" xs="24">
                                <div className='content0-block-item'>
                                    <div className="content-block-icon">
                                        <CarryOutTwoTone/>
                                    </div>
                                    <Title level={3}>Skill Assessment</Title>
                                    <Paragraph>Know where you stand</Paragraph>
                                </div>
                            </Col>
                            <Col className='content-block' md="8" xs="24">
                                <div className='content0-block-item'>
                                    <div className="content-block-icon">
                                        <DiffTwoTone/>
                                    </div>
                                    <Title level={3}>Comparison</Title>
                                    <Paragraph>See how you measure against industry standards</Paragraph>
                                </div>
                            </Col>
                            <Col className='content-block' md="8" xs="24">
                                <div className='content0-block-item'>
                                    <div className="content-block-icon">
                                        <FundTwoTone/>
                                    </div>
                                    <Title level={3}>Improvement Plans</Title>
                                    <Paragraph>Customized routes to skill up</Paragraph>
                                </div>
                            </Col>
                        </QueueAnim>
                    </OverPack>
                    <Row justify={'center'} align={'middle'}>
                        <Link to={`/app/skills/position/new-wizard`} style={{ margin: '0 auto' }}>
                            <Button className='banner-button'>
                                Start Your Assessment
                            </Button>
                        </Link>
                    </Row>
                </div>
            </div>
        );
    }
}
