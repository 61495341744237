import { Steps } from 'antd';
import { useNavigate } from 'react-router-dom';

const items = [
    {
        title: 'Select Categories',
        link: '/app/skills/position/new-wizard',
    },
    {
        title: 'Select Skills',
        link: '/app/skills/position/new-wizard/skills',
    },
    {
        title: 'Profile Review',
        link: '/app/skills/position/new-wizard/profile',
    },
    {
        title: 'Profile Review',
        link: '/app/skills/position/new-wizard/measure',
    }
];

export const WizardSteps = ({ current }: { current: number }) => {
    const navigate = useNavigate();

    return <Steps
        style={{marginBottom: '30px'}}
        onChange={(current) => { navigate(items[current].link) }}
        current={current}
        items={items}
    />
}
