import { makeAutoObservable } from "mobx";
import { ProfileAttachedMeasurements } from '../../model/StoredProfile';

class ComparisonStore {
    selectedItems: ProfileAttachedMeasurements[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    addSelectedItem(item: ProfileAttachedMeasurements) {
        this.selectedItems.push(item);
    }

    removeSelectedItem(item: ProfileAttachedMeasurements) {
        this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
    }

    getSelectedItemsQuery() {
        return this.selectedItems.map(item => `measurementId=${item.id}`).join('&');
    }

    clearSelectedItems() {
        this.selectedItems = [];
    }
}

export const comparisonStore = new ComparisonStore();
