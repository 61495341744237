import { Card, Typography, Avatar, Space, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { wizardStore } from '../../stores/WizardStore';
import { WizardSteps } from './WizardSteps';
import { Link } from 'react-router-dom';
import React from 'react';

const { Text } = Typography;

export const ProfileReviewStep = observer(() => {
    const { selectedSkills, selectedCategories } = wizardStore;
    const selectedCategorySlugs = selectedCategories
        .slice() // mobx forces it
        .sort((a, b) => a.slug.localeCompare(b.slug));

    return (
        <div>
            <WizardSteps current={2}/>
            {selectedCategorySlugs.map(category => {
                const skillsInCategory = selectedSkills
                    .filter(skill => skill.categorySlug === category.slug)
                    .sort((a, b) => a.name.localeCompare(b.name));
                if (skillsInCategory.length === 0) {
                    return null;
                }

                return (
                    <Card title={category.description} style={{ marginBottom: '1em' }} key={category.slug}>
                        {skillsInCategory.map(skill => (
                            <Space style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }} key={skill.skillId}>
                                <Avatar>{skill.name[0].toUpperCase()}</Avatar>
                                <div>
                                    <Text strong>{skill.name}: </Text>
                                    <Text type="secondary">{skill.description}</Text>
                                </div>
                            </Space>
                        ))}
                    </Card>
                );
            })}
            <Link to={'/app/skills/position/new-wizard/measure'}>
                <Button size='large' type="primary" style={{ marginTop: '16px' }}>
                    Next
                </Button>
            </Link>
            <Link to={'/app/skills/position/profile'}>
                <Button size='large' style={{ marginLeft: '8px', marginTop: '16px' }}>
                    Previous
                </Button>
            </Link>
        </div>
    );
});
