import React from 'react';
import { Link } from './react-router-wrappers/Link';
import { themeSeeds } from './themeSeeds';

export function HeaderContent() {
    return <>
        <div className={'logo'} style={{fontSize: '1.4em', paddingLeft: '50px'}}>
            <Link to="/" style={{color: themeSeeds.colorPrimary }}>
                <h3>47 Seconds</h3>
                {/*<Logo size={bigScreen ? 'full' : 'small'}/>*/}
            </Link>
        </div>
        {/*{bigScreen ?*/}
        {/*    navigationElement : (<div className={'burger-button'}>*/}
        {/*        <Button type="ghost" onClick={showDrawer}>*/}
        {/*            <MenuOutlined/>*/}
        {/*        </Button>*/}
        {/*        <Drawer*/}
        {/*            title="Menu"*/}
        {/*            placement="right"*/}
        {/*            closable={true}*/}
        {/*            onClose={onClose}*/}
        {/*            open={visible}*/}
        {/*        >*/}
        {/*            <div className="nav-in-drawer">*/}
        {/*                {navigationElement}*/}
        {/*            </div>*/}
        {/*        </Drawer>*/}
        {/*    </div>)*/}
        {/*}*/}
    </>;
}
