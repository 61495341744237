import React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Button, Alert, List, Card, theme } from 'antd';
import { wizardStore } from '../../stores/WizardStore';
import { Category } from '../../../model/Category';
import { useNavigate } from 'react-router-dom';
import { WizardSteps } from './WizardSteps';

const CategorySelectionStep: React.FC = observer(() => {
    // Destructure the store for convenience
    const { categories, loadingCategories, selectCategory, deselectCategory, getSelectedCategories, isCategorySelected } = wizardStore;
    const { token } = theme.useToken();

    // Function to handle the checkbox changes
    const handleCategoryChange = (checked: boolean, category: Category) => {
        if (checked) {
            selectCategory(category);
        } else {
            deselectCategory(category);
        }
    };

    // Function to check if the user has selected at least 3 categories
    const canProceed = () => getSelectedCategories().length >= 3;
    const navigate = useNavigate()

    // Function to handle moving to the next step of the wizard
    const handleNextStep = () => {
        navigate('/app/skills/position/new-wizard/skills')
    };

    // Call loadCategories if the categories haven't been loaded yet
    React.useEffect(() => {
        if (!categories) {
            wizardStore.loadCategories();
        }
    }, [categories]);

    if (wizardStore.categoriesLoader.state.kind === 'error') {
        return <Alert message="Failed to load categories" type="error" description={wizardStore.categoriesLoader.error}/>;
    }

    return (
        <div>
            <WizardSteps current={0} />
            <h1>Select at least 3 categories</h1>
            <List
                grid={{ gutter: 16, column: 1 }} // Setting column to 1 makes the card occupy the full row
                dataSource={categories === null ? void 0 : categories}
                loading={loadingCategories}
                renderItem={(category) => (
                    <List.Item>
                        <Card size="default" style={{
                            backgroundColor: isCategorySelected(category) ? token.colorSuccessActive : undefined, // Setting background color if selected
                        }}>
                            <Checkbox
                                key={category.slug}
                                style={{ fontSize: '18px' }}
                                checked={isCategorySelected(category)}
                                onChange={(e) => handleCategoryChange(e.target.checked, category)}
                            >
                                {category.name} - {category.description}
                            </Checkbox>
                        </Card>
                    </List.Item>
                )}
            />
            <Button type="primary" size="large" disabled={!canProceed()} onClick={handleNextStep} title={canProceed() ? '' : 'Please select at least 3 categories'}>
                Next
            </Button>
        </div>
    );
});

export default CategorySelectionStep;
