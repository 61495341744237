import { makeAutoObservable, observable, runInAction } from 'mobx';
import { AsyncData } from './AsyncData';
import { loadLearningSuggestions, SkillSuggestion } from '../api/aiAssistant';

class LearningAssistantStore {
    @observable
    public categoriesRecommendations: { [key: string]: AsyncData<SkillSuggestion[]> } = {};

    constructor() {
        makeAutoObservable(this);
    }

    public loadLearningSuggestions(profileId: string, measurementId: string, categorySlug: string) {
        const categoryRecommendations = new AsyncData(async () => {
            return loadLearningSuggestions(profileId, measurementId, categorySlug)
                .then(response => response.suggestions);
        });

        runInAction(() => {
            this.categoriesRecommendations[categorySlug] = categoryRecommendations;
        });

        categoryRecommendations.start();
    }

    public reset() {
        this.categoriesRecommendations = {};
    }
}

export const learningAssistantStore = new LearningAssistantStore();
