import React from 'react';
import 'antd/dist/reset.css';
import './App.css';
import { Outlet } from 'react-router-dom';
import { Card, Layout } from 'antd';
import { HeaderContent } from './HeaderContent';
import { SecondsFooter } from './SecondsFooter';

const { Header, Content } = Layout;

const contentStyle: React.CSSProperties = {
    minHeight: 120,
    lineHeight: '30px',
};

function App() {
    return (
        <Layout className="page-container">
            {/*<MetaAttrs/>*/}
            <Header
                style={{
                    paddingInline: '0'
                }}>
                <HeaderContent/>
            </Header>
            <Content style={contentStyle}>
                <Card>
                    <Outlet/>
                </Card>
            </Content>
            <SecondsFooter/>
        </Layout>
    );
}

export default App;
